* {box-sizing: border-box}
.mySlides1, .mySlides2 {display: none}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  max-height: 600px;
  position: relative;
  margin: auto;
  overflow: hidden; /* Ensure content doesn't overflow */
}

img {
  width: 100%;
  height: auto;
  max-height: 600px; /* Ensure images do not exceed the max height of the container */
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover, .next:hover {
  background-color: #f1f1f1;
  color: black;
}

.caption {
  color: black;
  font-size: 16px;
  padding: 8px 12px;
  position: absolute;
  bottom: 20px; /* Positioned at the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  width: auto;
  max-width: 80%; /* Adjust maximum width */
  height: auto;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.523);
  border-radius: 10px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .prev, .next {
    font-size: 14px;
    padding: 10px;
  }

  .caption {
    font-size: 14px;
    padding: 6px 10px;
    bottom: 15px; /* Adjust bottom margin */
    max-width: 90%; /* Adjust maximum width for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .prev, .next {
    font-size: 12px;
    padding: 8px;
  }

  .caption {
    /* font-size: 12px;
    padding: 4px 8px;
    bottom: 10px; */
     /* Adjust bottom margin */
    /* max-width: 100%;  */
    /* Adjust maximum width for smaller screens */
    display: none;
  }
}
