.cursor-text{
    font-size: 3em;
    font-family: monospace;
    font-weight: bold;
    color: black;
    text-align:center;
}
@media (max-width:600px) {
    .cursor-text{
        font-size: 2em;
        font-weight: 100;
    }
}