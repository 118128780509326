.divstyle {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    background-size: cover;
    height: 800px;
}
.caption-span{
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    margin:650px auto;
    height: 100px;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    line-height: 100px;
    font-weight: bold;
}
@media (max-width:600px) {
    .divstyle {
        height: 350px;
    }
    .caption-span{
        height: 80px;
        width: 150px;
        line-height: 80px;
        margin: 225px auto;

    }
}