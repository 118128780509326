.header {
    top: 0;
    /* background-color: rgba(46, 136, 134, 0.432); */
    /* box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19); */
    /* backdrop-filter: blur(0.8px); */
    width: 100%;
    height: 150px;
    position: fixed;
    z-index: 1000;
     /* border: 1px solid rgba(255, 255, 255, 0.18); */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */

    /* background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px; */
 background: rgba(255, 255, 255, 0.1); /* Lighter background */
 box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1); /* Add a subtle shadow for better contrast */
 backdrop-filter: blur(8px); /* Increase blur */
 -webkit-backdrop-filter: blur(8px);
 border-radius: 10px;
 /* border: 1px solid rgba(255, 255, 255, 0.3);  */
 /* A lighter border */
 border-bottom-right-radius: 250px;
 /* border: 2px solid lightblue; */
}

.logo-size {
    height: 120px;
    margin-top: 10px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    background:none;
}

.header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.School-Details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-bar {
    display: flex;
    flex-direction: row;
    gap: 45px;
}

.menu-icon {
    display: none;
    color: white;
    cursor: pointer;
}

.link-style {
    color: black;
}

.School-tabs {
    display: flex;
    flex-direction: column;
    color: white;
}

.school-name{
    color: black;
    font-weight: bolder;
    font-size: 25px;
}

@media (max-width: 600px) {
    .header {
        height: 150px;
        z-index: 1000;
        border-bottom-right-radius: 0;
        padding: 10px;
    }

    .logo-size {
        height: 100px;
        width: 100px;
        /* margin-top: 10px; */
    }

    .School-Details {
        display: none;
    }

    .nav-bar {
        display: none;
        flex-direction: column;
        /* gap: 10px; */
        background-color: white;
        border-radius: 5px;
        position: fixed;
        top: 150px;
        /* max-width: 300px; */
        /* left: 60px; */
        width: 100%;
        z-index: 1000;
        padding: 10px;
        height: max-content;
    }

    .link-style {
        color: black;
        text-align: center;
        font-weight: 600;
        margin-left: 50px;
    }

    .nav-bar.open {
        display: flex;
        margin-right: 100px;
    }

    .menu-icon {
        display: block;
        /* background-color: blue; */
        margin-top: 25px;
        margin-left: 1px;
        /* position: fixed; */
    }

    .School-tabs {
        flex-direction: row;
    }

    /* .header-content {
        justify-content: space-evenly;
    } */

    .school-name {
        /* margin-top: 30px; */
        margin-left: 20px;
        font-size: 20px;
        align-items: center;
        align-content: center;
    }
}