body {
  background-color:rgba(255, 255, 255, 0.612);
  color: black;
}

.mission-word {
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  color: 'red';
}

.pink-card {
  background-color: rgba(18, 148, 204, 0.679);
  display: flex;
  color: white;
  flex-direction: column;
  margin: 0 200px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(46, 198, 240, 0.4) -5px 5px, rgba(46, 230, 240, 0.3) -10px 10px, rgba(46, 124, 240, 0.2) -15px 15px, rgba(12, 148, 179, 0.633) -20px 20px, rgba(0, 0, 0, 0.277) -25px 25px;
}

.glassEffect-alignment {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Facilities-direction {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Facilities-direction::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.Facilities-container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 300px;
  height: 520px;
  border-radius: 10px;
  flex: 0 0 auto;
  margin: 10px;
}

.School-Activities-Direction {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
}

.School-activities-image {
  height: 500px;
  width: 350px;
  border-radius: 10px;
  opacity: 0.9;
}

.School-activities-h1 {
  position: absolute;
  top: 100px;
  z-index: 1;
  font-size: 24px;
  font-weight: bold;
  left: 110px;
  border: 5px solid white;
  color: white;
  padding: 5px;
  border-radius: 10px;
  width: 120px;
  text-align: center;
}

.Footer {
  background-color: black;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
}

@media (max-width: 600px) {
  .mission-word {
    font-size: 30px;
  }

  .pink-card {
    margin: 30px;
    padding: 5px;
  }

  .glassEffect-alignment {
    flex-direction: column;
  }

  .Facilities-direction {
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
  }

  .Facilities-container {
    width: 250px;
    display: block;
    margin: 0 10px;
    height: 500px;
  }

  .School-Activities-Direction {
    flex-direction: column;
    align-items: center;
  }

  .School-activities-image {
    height: auto;
    width: 90%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .School-activities-h1 {
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    width: 100px;
  }

  .Footer {
    flex-direction: column;
  }
}
