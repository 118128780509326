.upload-container {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-title {
  font-weight: bold;
  text-align: center;
  color: blue;
  margin-bottom: 20px;
  font-size: 24px;
}

.upload-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.upload-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button p {
  margin: 0;
}

.upload-button:hover {
  background-color: darkblue;
}

@media (max-width: 600px) {
  .upload-form {
    width: 95%;
    padding: 15px;
  }
}
